var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('mdb-modal',{attrs:{"centered":"","elegant":"","scrollable":"","size":"lg","show":_vm.mostrarModal},on:{"close":function($event){_vm.$emit('cerrar', false); _vm.limpiarCampos();}}},[_c('mdb-modal-header',[_c('mdb-modal-title',[_vm._v(" "+_vm._s(_vm.tituloModal)+" ")])],1),_c('mdb-modal-body',[(!_vm.esCrearReceptor)?_c('div',{staticClass:"row"},[_c('div',{class:['col-12 col-md-6',
            {'campo': _vm.validarValores.estado
              && _vm.validarValores.estado.length},
            {'valido': _vm.validarValores.estado == 'valido'},
            {'invalido': _vm.validarValores.estado == 'invalido'} ]},[_c('div',{staticClass:"md-form md-outline outline-select my-2"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.direccion.estado),expression:"direccion.estado"}],staticClass:"custom-select",attrs:{"id":"estado-select"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.direccion, "estado", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){_vm.direccion.ciudad = ''}]}},[_c('option',{staticClass:"d-none",attrs:{"disabled":"","value":""}},[_vm._v("Seleccione")]),_vm._l((_vm.estados),function(value,key){return _c('option',{key:key+'estado',domProps:{"value":value.nombre}},[_vm._v(" "+_vm._s(value.nombre)+" ")])})],2),_c('label',{class:_vm.direccion.estado ? 'label-active' : 'label-inactive',attrs:{"for":"estado-select"}},[_vm._v(" Estado ")])]),(_vm.validarValores.estado == 'invalido')?_c('p',{staticClass:"mensaje-invalido"},[_vm._v(" Selecciona el estado ")]):_vm._e()]),_c('div',{class:['col-12 col-md-6',
            {'campo': _vm.validarValores.ciudad
              && _vm.validarValores.ciudad.length},
            {'valido': _vm.validarValores.ciudad == 'valido'},
            {'invalido': _vm.validarValores.ciudad == 'invalido'} ]},[_c('div',{staticClass:"md-form md-outline outline-select my-2"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.direccion.ciudad),expression:"direccion.ciudad"}],staticClass:"custom-select",attrs:{"id":"ciudad-select"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.direccion, "ciudad", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{staticClass:"d-none",attrs:{"disabled":"","value":""}},[_vm._v("Seleccione")]),_vm._l((_vm.ciudades),function(value,key){return _c('option',{key:key+'ciudad',domProps:{"value":value.nombre}},[_vm._v(" "+_vm._s(value.nombre)+" ")])})],2),_c('label',{class:_vm.direccion.ciudad ? 'label-active' : 'label-inactive',attrs:{"for":"ciudad-select"}},[_vm._v(" Ciudad ")])]),(_vm.validarValores.ciudad == 'invalido')?_c('p',{staticClass:"mensaje-invalido"},[_vm._v(" Selecciona la ciudad ")]):_vm._e()]),_c('div',{class:['col-12',
            {'campo': _vm.validarValores.direccion
              && _vm.validarValores.direccion.length},
            {'valido': _vm.validarValores.direccion == 'valido'},
            {'invalido': _vm.validarValores.direccion == 'invalido'} ]},[_c('mdb-input',{staticClass:"my-2",attrs:{"type":"textarea","label":"Dirección","rows":2,"outline":""},model:{value:(_vm.direccion.direccion),callback:function ($$v) {_vm.$set(_vm.direccion, "direccion", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"direccion.direccion"}}),(_vm.validarValores.direccion == 'invalido')?_c('p',{staticClass:"mensaje-invalido textarea"},[_vm._v(" Indica la dirección ")]):_vm._e()],1),_c('div',{staticClass:"col-12"},[_c('mdb-input',{staticClass:"my-2",attrs:{"type":"textarea","label":"Observación de envío (opcional)","rows":2,"outline":""},model:{value:(_vm.direccion.observacion),callback:function ($$v) {_vm.$set(_vm.direccion, "observacion", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"direccion.observacion"}})],1)]):_vm._e(),((_vm.receptor && _vm.esEditar) || (!_vm.esEditar) || (_vm.esCrearReceptor))?_c('div',[_c('header',{staticClass:"mt-2 font-weight-bold"},[_vm._v("Recibe")]),_c('div',{staticClass:"row"},[_c('div',{class:['col-12 col-md-6',
              {'campo': _vm.validarValores.rnombre
                && _vm.validarValores.rnombre.length},
              {'valido': _vm.validarValores.rnombre == 'valido'},
              {'invalido': _vm.validarValores.rnombre == 'invalido'} ]},[_c('mdb-input',{staticClass:"my-2",attrs:{"type":"text","label":"Nombre","outline":""},model:{value:(_vm.direccion.recibe.nombre),callback:function ($$v) {_vm.$set(_vm.direccion.recibe, "nombre", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"direccion.recibe.nombre"}}),(_vm.validarValores.rnombre == 'invalido')?_c('p',{staticClass:"mensaje-invalido"},[_vm._v(" Indica el nombre ")]):_vm._e()],1),_c('div',{class:['col-12 col-md-6',
              {'campo': _vm.validarValores.ridentificacion
                && _vm.validarValores.ridentificacion.length},
              {'valido': _vm.validarValores.ridentificacion == 'valido'},
              {'invalido': _vm.validarValores.ridentificacion == 'invalido'} ]},[_c('mdb-input',{staticClass:"my-2",attrs:{"label":"Número de ID","outline":""},model:{value:(_vm.direccion.recibe.identificacion),callback:function ($$v) {_vm.$set(_vm.direccion.recibe, "identificacion", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"direccion.recibe.identificacion"}}),(_vm.validarValores.ridentificacion == 'invalido')?_c('p',{staticClass:"mensaje-invalido"},[_vm._v(" Indica la identificación ")]):_vm._e()],1),(!_vm.esEditar)?_c('div',{staticClass:"col-12 col-lg-6"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{class:['col pr-1 pr-sm-3',
                  {'campo': _vm.validarValores.rtelefono
                    && _vm.validarValores.rtelefono.length},
                  {'valido': _vm.validarValores.rtelefono == 'valido'},
                  {'invalido': _vm.validarValores.rtelefono == 'invalido'} ]},[_c('mdb-input',{staticClass:"my-2 plus-addon",attrs:{"id":"input-rtelefono","type":"tel","label":"Teléfono","placeholder":"Ej: 58426573828","minlength":"7","maxlength":"12","outline":""},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.validarTelefono(_vm.rtelefono)}},model:{value:(_vm.rtelefono),callback:function ($$v) {_vm.rtelefono=_vm._n($$v)},expression:"rtelefono"}},[_c('span',{staticClass:"input-group-text md-addon",attrs:{"slot":"prepend"},slot:"prepend"},[_vm._v("+")])]),(_vm.validarValores.rtelefono == 'invalido')?_c('p',{staticClass:"mensaje-invalido prepend"},[_vm._v(" Indica el teléfono ")]):_vm._e()],1),_c('mdb-btn',{staticClass:"col-auto mr-3 px-2",attrs:{"color":"exitoso","icon":"plus","size":"sm","title":"Agregar teléfono"},on:{"click":function($event){return _vm.validarTelefono(_vm.rtelefono)}}},[_vm._v(" Añadir ")])],1),_vm._l((_vm.direccion.recibe.telefonos),function(tel,i){return _c('div',{key:'Rteléfono'+i,staticClass:"chip delete-btn"},[_vm._v(" +"+_vm._s(tel)+" "),_c('mdb-btn',{staticClass:"m-0 ml-2 p-1 pb-2",attrs:{"dark-waves":"","flat":"","icon":"trash","size":"sm","title":"Eliminar teléfono"},on:{"click":function($event){return _vm.rtelefonoRemover(i)}}})],1)})],2):_vm._e(),(!_vm.esEditar)?_c('div',{staticClass:"col-12 col-lg-6"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{class:['col pr-1 pr-sm-3',
                  {'campo': _vm.validarValores.remail
                    && _vm.validarValores.remail.length},
                  {'valido': _vm.validarValores.remail == 'valido'},
                  {'invalido': _vm.validarValores.remail == 'invalido'} ]},[_c('mdb-input',{staticClass:"my-2",attrs:{"id":"input-remail","type":"email","label":"Email","placeholder":"Ej: ejemplo@mail.com","outline":""},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.validarEmail(_vm.remail)}},model:{value:(_vm.remail),callback:function ($$v) {_vm.remail=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"remail"}}),(_vm.validarValores.remail == 'invalido')?_c('p',{staticClass:"mensaje-invalido"},[_vm._v(" Indica el email ")]):_vm._e()],1),_c('mdb-btn',{staticClass:"col-auto mr-3 px-2",attrs:{"color":"exitoso","icon":"plus","size":"sm","title":"Agregar email"},on:{"click":function($event){return _vm.validarEmail(_vm.remail)}}},[_vm._v(" Añadir ")])],1),_vm._l((_vm.direccion.recibe.emails),function(email,i){return _c('div',{key:'Remail'+i,staticClass:"chip delete-btn"},[_vm._v(" "+_vm._s(email)+" "),_c('mdb-btn',{staticClass:"m-0 ml-2 p-1 pb-2",attrs:{"dark-waves":"","flat":"","icon":"trash","size":"sm","title":"Eliminar email"},on:{"click":function($event){return _vm.remailRemover(i)}}})],1)})],2):_vm._e()])]):_vm._e(),_c('div',{staticClass:"row justify-content-around"},[_c('mdb-btn',{staticClass:"col-auto btn-bordeado mt-3 px-3",attrs:{"flat":"","dark-waves":"","icon":"times"},on:{"click":function($event){return _vm.$emit('cerrar', false)}}},[_vm._v(" Cancelar ")]),_c('mdb-btn',{staticClass:"col-auto mt-3 px-3",attrs:{"color":"primario","icon":_vm.botonDeshabilitado ? 'circle-notch' : _vm.botonPrincipal.icono,"icon-class":_vm.botonDeshabilitado ? 'fa-spin' : '',"disabled":_vm.botonDeshabilitado},on:{"click":_vm.validarDireccion}},[_vm._v(" "+_vm._s(_vm.botonPrincipal.texto)+" ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }