<template>
  <div>
    <!-- header -->
    <div class="encabezado-pagina">
      <h2 class="col user-select-none">Direcciones</h2>
      <div v-if="!$apolloData.queries.cliente.loading" class="col-auto">
        <mdb-btn
          color="primario"
          icon="plus"
          :disabled="botonDeshabilitado"
          class="my-sm-0 px-3"
          @click="mostrarModalDirecciones = true;"
        >
          Nueva dirección
        </mdb-btn>
      </div>
    </div>
    <CargandoVista
      v-if="$apolloData.queries.cliente.loading"
      clase-adicional="vc-75vh"
    />
    <div v-else class="mb-page">
      <!-- Tabla -->
      <ul
        data-table="sticky"
        class="table my-3"
      >
        <li class="encabezado row align-items-end">
          <div class="col">
            <span class="d-lg-none">Detalles</span>
            <span class="d-none d-lg-block">Estado / Ciudad</span>
          </div>
          <div class="col d-none d-lg-block">Dirección</div>
          <div class="col d-none d-sm-block">Persona que recibe</div>
          <div class="col d-none d-md-block">Observaciones</div>
          <div class="col-auto acciones">Acción</div>
        </li>
        <li
          v-if="!(cliente && cliente.direcciones && cliente.direcciones.length)"
          class="no-items"
        >
          No hay direcciones
        </li>
        <li
          v-else
          v-for="(direccion, iD) in cliente.direcciones"
          :key="`direccion-${iD}`"
          class="contenido normal row align-items-center"
        >
        <!-- Estado / ciudad -->
          <div class="col">
            {{ direccion.ciudad.estado.nombre }},
            {{ direccion.ciudad.nombre }}
            <!-- Dirección-->
            <p class="my-2 d-lg-none">
              Dirección: {{ direccion.direccion }}
            </p>
            <!-- Persona que recibe-->
            <div class="my-2 d-sm-none">
              <p class="mb-0">
                Receptor:
                <span
                  :class="{'text-muted font-italic': !(direccion.recibe && direccion.recibe.nombre)}"
                >
                  {{ direccion.recibe && direccion.recibe.nombre
                    ?  direccion.recibe.nombre : 'Sin especificar'
                  }}
                </span>
              </p>
              <div
                v-if="direccion.recibe && direccion.recibe.telefonos && direccion.recibe.telefonos.length && direccion.recibe.telefonos[0].numero"
                class="row ml-n1 mr-0 my-1 mr-sm-3"
              >
                <div class="col px-0">
                  <mdb-btn
                    block flat dark-waves
                    tag="a"
                    :href="`tel:+${direccion.recibe.telefonos[0].numero}`"
                    role="button"
                    icon="phone-alt"
                    icon-class="fa-2x"
                    size="sm"
                    class="btn-bordeado m-1 px-1 py-2"
                    title="Llamada"
                    @click.native.stop
                  />
                </div>
                <div class="col px-1">
                  <mdb-btn
                    block flat dark-waves
                    tag="a"
                    :href="`sms:+${direccion.recibe.telefonos[0].numero}`"
                    role="button"
                    icon="sms"
                    icon-class="fa-2x"
                    size="sm"
                    class="btn-bordeado m-1 px-1 py-2"
                    title="SMS"
                    @click.native.stop
                  />
                </div>
                <div class="col px-0">
                  <mdb-btn
                    block flat dark-waves
                    tag="a"
                    :href="`https://wa.me/${direccion.recibe.telefonos[0].numero}`"
                    role="button"
                    fab
                    icon="whatsapp"
                    icon-class="fa-2x"
                    size="sm"
                    class="btn-bordeado m-1 px-1 py-2"
                    title="WhatsApp"
                    @click.native.stop
                  />
                </div>
              </div>
              <!-- Si existe un receptor se podrá crear, editar o eliminar sus teléfonos -->
              <mdb-btn
                v-if="direccion.recibe && direccion.recibe.nombre"
                flat
                dark-waves
                icon="phone-alt"
                size="sm"
                class="btn-bordeado mx-0 my-0 py-2 px-3"
                title="Haz clic para ver, crear y actualizar los teléfonos"
                @click="mostrarCRUDTelefonos = true; direccionSeleccionada = direccion;"
              >
                Teléfonos
              </mdb-btn>
              <!-- Si no existe un receptor se podrá crear -->
              <mdb-btn
                v-if="!direccion.recibe"
                flat
                icon="user-plus"
                dark-waves
                size="sm"
                class="btn-bordeado py-2 px-3"
                title="Haz clic para ver, crear y actualizar los teléfonos"
                @click="esCrearReceptor=true; direccionSeleccionada = direccion;
                  mostrarModalDirecciones=!mostrarModalDirecciones;"
              >
                Asignar receptor
              </mdb-btn>
            </div>
            <!-- Observaciones -->
            <div class="my-2 d-md-none">
              Observaciones: {{direccion.observacion ? direccion.observacion : 'Sin especificar'}}
            </div>
          </div>
          <!-- Dirección-->
          <div class="col d-none d-lg-block">
            {{ direccion.direccion ? direccion.direccion : 'Sin especificar' }}
          </div>
          <!-- Persona que recibe-->
          <div class="col d-none d-sm-block">
            <span
              :class="['d-block',
                {'text-muted font-italic': !(direccion.recibe && direccion.recibe.nombre)}
              ]"
            >
              {{ direccion.recibe && direccion.recibe.nombre
                ?  direccion.recibe.nombre : 'Sin especificar'
              }}
            </span>
            <!-- Si no existe un receptor se podrá crear -->
            <mdb-btn
              v-if="!direccion.recibe"
              flat
              icon="user-plus"
              dark-waves
              size="sm"
              class="btn-bordeado py-2 px-3"
              title="Haz clic para ver, crear y actualizar los teléfonos"
              @click="esCrearReceptor=true; direccionSeleccionada = direccion;
                mostrarModalDirecciones=!mostrarModalDirecciones;"
            >
              Asignar receptor
            </mdb-btn>
            <div
              v-if="direccion.recibe && direccion.recibe.telefonos && direccion.recibe.telefonos.length && direccion.recibe.telefonos[0].numero"
              class="row ml-n1 mr-0 my-1 mr-sm-3"
            >
              <div class="col px-0">
                <mdb-btn
                  block flat dark-waves
                  tag="a"
                  :href="`tel:+${direccion.recibe.telefonos[0].numero}`"
                  role="button"
                  icon="phone-alt"
                  icon-class="fa-2x"
                  size="sm"
                  class="btn-bordeado m-1 px-1 py-2"
                  title="Llamada"
                  @click.native.stop
                />
              </div>
              <div class="col px-1">
                <mdb-btn
                  block flat dark-waves
                  tag="a"
                  :href="`sms:+${direccion.recibe.telefonos[0].numero}`"
                  role="button"
                  icon="sms"
                  icon-class="fa-2x"
                  size="sm"
                  class="btn-bordeado m-1 px-1 py-2"
                  title="SMS"
                  @click.native.stop
                />
              </div>
              <div class="col px-0">
                <mdb-btn
                  block flat dark-waves
                  tag="a"
                  :href="`https://wa.me/${direccion.recibe.telefonos[0].numero}`"
                  role="button"
                  fab
                  icon="whatsapp"
                  icon-class="fa-2x"
                  size="sm"
                  class="btn-bordeado m-1 px-1 py-2"
                  title="WhatsApp"
                  @click.native.stop
                />
              </div>
            </div>
            <!-- Si existe un receptor se podrá crear, editar o eliminar sus teléfonos -->
            <mdb-btn
              v-if="direccion.recibe && direccion.recibe.nombre"
              flat
              dark-waves
              icon="phone-alt"
              size="sm"
              class="btn-bordeado mx-0 my-0 py-2 px-3"
              title="Haz clic para ver, crear y actualizar los teléfonos"
              @click="mostrarCRUDTelefonos = true; direccionSeleccionada = direccion;"
            >
              Teléfonos
            </mdb-btn>
          </div>
          <!-- Observaciones -->
          <div class="col d-none d-md-block">
            {{direccion.observacion ? direccion.observacion : 'Sin especificar'}}
          </div>
          <!-- ACCION -->
          <div class="col-auto acciones">
            <mdb-btn
              flat
              dark-waves
              icon="trash"
              :disabled="botonDeshabilitado"
              icon-class="texto-peligro"
              class="m-0 ml-2 ml-xl-0 py-3 px-2 btn-bordeadoOnHover"
              title="Eliminar dirección"
              @click="IdDireccionAEliminar = direccion.id;
                mostrarModalConfirmarEliminar = true;
                sustantivoFemenino = true"
            />
            <mdb-btn
              flat
              dark-waves
              icon="pen"
              :disabled="botonDeshabilitado"
              class="m-0 ml-2 py-3 px-2 btn-bordeadoOnHover"
              title="Editar dirección"
              @click="esEditarDirección=true; direccionSeleccionada = direccion;
                mostrarModalDirecciones=!mostrarModalDirecciones;"
            />
          </div>
        </li>
      </ul>
    </div>
    <!-- Modal para agregar / editar teléfonos del receptor -->
    <mdb-modal
      centered
      elegant
      scrollable
      size="lg"
      :show="mostrarCRUDTelefonos"
      @close="limpiarModalCRUDTelefonos"
    >
      <mdb-modal-header>
        <mdb-modal-title>
          Teléfonos de
          {{ direccionSeleccionada.recibe && direccionSeleccionada.recibe.nombre
            ? direccionSeleccionada.recibe.nombre
            : ''
          }}
        </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <header
          class="user-select-none cursor-pointer pb-2"
          title="Haz clic para agregar nuevo teléfono"
          @click="mostrarNuevoRTelefono = !mostrarNuevoRTelefono"
        >
          <font-awesome-icon
            icon="plus-circle"
            size="sm"
            class="text-success"
          />
          Agregar
        </header>
        <div class="row align-items-center justify-content-center">
          <div class="col-12 col-sm">
            <mdb-input
              v-if="mostrarNuevoRTelefono"
              v-model.number="nuevoRTelefono"
              type="tel"
              label="Teléfono"
              placeholder="Ej: 58426573828"
              minlength="7"
              maxlength="12"
              class="my-2 plus-addon"
              outline
              @keyup.enter.native="validarTelefono(nuevoRTelefono, true, direccionSeleccionada.recibe.id)"
            >
              <span class="input-group-text md-addon" slot="prepend">+</span>
            </mdb-input>
          </div>
          <div class="col-auto">
            <mdb-btn
              v-if="mostrarNuevoRTelefono"
              color="exitoso"
              icon="check"
              title="Agregar teléfono"
              class="m-1 py-2 px-3"
              @click="validarTelefono(nuevoRTelefono, true, direccionSeleccionada.recibe.id)"
            >
              Agregar
            </mdb-btn>
          </div>
        </div>
        <div
          v-if="direccionSeleccionada.recibe
            && direccionSeleccionada.recibe.telefonos
            && direccionSeleccionada.recibe.telefonos.length"
        >
          <ul class="list-unstyled row mb-2">
            <li v-for="(tel, i) in direccionSeleccionada.recibe.telefonos" :key="`tel${i}`" class="col-12 col-lg-6">
              <!-- Si se selecciona un teléfono para editar, se sustituirá el número teléfono por un
              input que permite editarlo  -->
              <div v-if="editarRTelefono">
                <div
                  v-if="rTelefonoEditarSeleccionado
                    && rTelefonoAEditar.telefonoIndice===i"
                >
                  <mdb-input
                    v-model.number="rTelefonoAEditar.telefonoNuevo"
                    type="tel"
                    label="Teléfono"
                    minlength="7"
                    maxlength="12"
                    class="my-2 plus-addon"
                    outline
                    @keyup.enter.native="
                      validarTelefono(rTelefonoAEditar.telefonoNuevo, false, direccionSeleccionada.recibe.id)
                    "
                  >
                    <span class="input-group-text md-addon" slot="prepend">+</span>
                  </mdb-input>
                  <div class="mb-2 text-center">
                    <mdb-btn
                      color="exitoso"
                      icon="sync"
                      title="Actualizar teléfono"
                      class="m-1 py-2 px-3"
                      @click="validarTelefono(rTelefonoAEditar.telefonoNuevo, false, direccionSeleccionada.recibe.id)"
                    >
                      Actualizar
                    </mdb-btn>
                    <mdb-btn
                      flat
                      dark-waves
                      icon="times"
                      title="Cancelar edición del teléfono"
                      class="m-1 p-2 px-md-3"
                      @click="rTelefonoEditarSeleccionado = false;"
                    >
                      Cancelar
                    </mdb-btn>
                  </div>
                </div>
                <div v-else class="mb-2">
                  <span  class="d-block">
                    +{{tel.numero}}
                  </span>
                  <mdb-btn
                    flat
                    dark-waves
                    icon="pen"
                    title="Editar teléfono"
                    class="m-1 py-1 px-3"
                    @click="rTelefonoEditarSeleccionado = true;
                      rTelefonoAEditar = {
                        telefonoIndice: i,
                        telefonoNuevo: tel.numero,
                        telefonoAnterior: tel.numero
                      };"
                  >
                    Editar
                  </mdb-btn>
                  <mdb-btn
                    flat
                    dark-waves
                    icon="trash"
                    title="Eliminar teléfono"
                    class="m-1 py-1 px-3"
                    @click="telefonoRemover(tel.numero, direccionSeleccionada.recibe.id)"
                  >
                    Eliminar
                  </mdb-btn>
                </div>
              </div>
              <div v-else>
                <a
                  :href="`tel:+${tel.numero}`"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  +{{tel.numero}}
                </a>
                <div class="d-inline-block">
                  <a
                    :href="`https://wa.me/${tel.numero}`"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="btn btn-flat btn-bordeado ripple-parent m-1 py-1 px-3"
                  >
                    <font-awesome-icon :icon="['fab', 'whatsapp']" />
                    WA
                  </a>
                  <a
                    :href="`sms:+${tel.numero}`"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="btn btn-flat btn-bordeado ripple-parent m-1 py-1 px-3"
                  >
                    <font-awesome-icon icon="sms" />
                    Sms
                  </a>
                </div>
              </div>
            </li>
          </ul>
          <!-- Botón para editar teléfonos -->
          <mdb-btn
            block
            flat
            dark-waves
            :icon="botonEditarTelefono.icono"
            :title="botonEditarTelefono.title"
            class="m-0 mr-2 px-3 py-2"
            @click="editarRTelefono = !editarRTelefono;"
          >
            {{ botonEditarTelefono.title }}
          </mdb-btn>
        </div>
      </mdb-modal-body>
    </mdb-modal>
    <ConfirmacionEliminar
      :mostrar-modal="mostrarModalConfirmarEliminar"
      mensaje="Se eliminará la dirección en su totalidad"
      :sustantivoFemenino="true"
      @cerrar="mostrarModalConfirmarEliminar = $event"
      @eliminar="direccionRemover"
    />
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
    <!-- Fomulario direcciones -->
    <FormularioDirecciones
      :mostrar-modal="mostrarModalDirecciones"
      :clienteId="personaId"
      :esCrearReceptor="esCrearReceptor"
      :es-editar="esEditarDirección"
      :datosDireccion="direccionSeleccionada ? direccionSeleccionada : ''"
      @alertaMensaje="alertaMensaje=$event"
      @creado="cliente.direcciones.push($event); mostrarModalDirecciones = false;"
      @cerrar="mostrarModalDirecciones = $event; esEditarDirección=false; esCrearReceptor=false;"
    />
  </div>
</template>

<script>
import AlertaMensaje from '@/components/AlertaMensaje.vue'
import FormularioDirecciones from '@/components/FormularioDirecciones.vue'
import ConfirmacionEliminar from '@/components/ConfirmacionEliminar.vue'
import direccionEliminarGql from '@/graphql/direccionEliminar.gql'
import CargandoVista from '@/components/CargandoVista.vue'
import clienteDireccionesGql from '@/graphql/clienteDirecciones.gql'
import clienteEditarTelefonoGql from '@/graphql/clienteEditarTelefono.gql'
import clienteCrearTelefonoGql from '@/graphql/clienteCrearTelefono.gql'
import clienteEliminarTelefonoGql from '@/graphql/clienteEliminarTelefono.gql'
import { leerPersonaId } from '@/utils/datosToken.js'
import {
  validarTel
} from '@/funciones/funciones.js'
import {
  mdbBtn,
  mdbInput,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody
} from 'mdbvue'

export default {
  name: 'Direcciones',
  components: {
    mdbBtn,
    mdbInput,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    CargandoVista,
    AlertaMensaje,
    FormularioDirecciones,
    ConfirmacionEliminar
  },
  data () {
    return {
      // Crear receptor
      esCrearReceptor: false,
      // Modal CRUD Teléfonos
      mostrarCRUDTelefonos: false,
      // Confirmación eliminar
      mostrarModalConfirmarEliminar: false,
      // direcciones
      mostrarModalDirecciones: false,
      esEditarDirección: false,
      direccionSeleccionada: {},
      IdDireccionAEliminar: '',
      mostrarNuevoRTelefono: false,
      nuevoRTelefono: '',
      editarRTelefono: false,
      rTelefonoEditarSeleccionado: false,
      indiceDireccionSeleccionada: 0,
      rTelefonoAEditar: {
        telefonoIndice: 0,
        telefonoAnterior: '',
        telefonoNuevo: ''
      },
      // Otros
      alertaMensaje: { contenido: '' },
      botonDeshabilitado: false,
      personaId: ''
    }
  },
  mounted () {
    this.personaId = leerPersonaId()
  },
  computed: {
    botonEditarTelefono () {
      return this.editarTelefono
        ? { icono: 'times', title: 'Cancelar edición' }
        : { icono: 'pen', title: 'Editar teléfonos' }
    }
  },
  methods: {
    // Funcionalidad AlertaMensaje
    mostrarMensajeEnCasoDefault () {
      this.alertaMensaje = {
        contenido: 'Ha ocurrido un error inesperado. Por favor intenta de nuevo',
        tipo: 'error'
      }
    },
    // Direcciones
    direccionRemover () {
      this.botonDeshabilitado = true
      this.$apollo.mutate({
        mutation: direccionEliminarGql,
        variables: {
          direccionId: this.IdDireccionAEliminar
        }
      })
        .then(({ data: { direccionEliminar } }) => {
          const msg = direccionEliminar.codigo
          switch (msg) {
            case 'Correcto':
              this.alertaMensaje = {
                contenido: 'Se ha removido la dirección exitosamente',
                tipo: 'correcto'
              }
              break
            case 'Fallido':
              this.botonDeshabilitado = false
              return (this.alertaMensaje = {
                contenido: 'Ha ocurrido un error eliminando la dirección. Por favor intenta de nuevo',
                tipo: 'error'
              })
            default:
              this.botonDeshabilitado = false
              return this.mostrarMensajeEnCasoDefault()
          }

          this.cliente.direcciones = this.cliente.direcciones.filter((e) => {
            return !this.IdDireccionAEliminar.includes(e.id)
          })
          this.mostrarModalConfirmarEliminar = false
          this.botonDeshabilitado = false
        })
        .catch(() => {
          this.mostrarMensajeEnCasoDefault()
          this.botonDeshabilitado = false
        })
    },
    // Funcionalidad teléfonos
    validarTelefono (telefono, nuevo, receptorId) {
      if (telefono && telefono === this.rTelefonoAEditar.telefonoAnterior) {
        return (this.alertaMensaje = {
          contenido: 'El teléfono no puede ser idéntico al anterior',
          tipo: 'error'
        })
      }
      if (!telefono || !validarTel(telefono)) {
        return (this.alertaMensaje = {
          contenido: 'Teléfono no válido',
          tipo: 'error'
        })
      }
      // Validar teléfonos repetidos
      let proximosTelefonos = this.direccionSeleccionada.recibe.telefonos
      const telefonosCantidad = JSON.parse(JSON.stringify(proximosTelefonos.length))
      // Filtrar buscando
      proximosTelefonos = proximosTelefonos.filter((t) => String(t.numero) !== JSON.stringify(telefono))
      // Verifica si se eliminó elementos del array
      if (telefonosCantidad && proximosTelefonos.length !== telefonosCantidad) {
        return (this.alertaMensaje = {
          contenido: 'El teléfono que ingresaste ya se encuentra agregado',
          tipo: 'error'
        })
      }
      if (nuevo && receptorId) return this.telefonoCrear(telefono, receptorId)
      return this.telefonoEditar(telefono, receptorId)
    },
    telefonoCrear (tel, id) {
      const telefono = JSON.stringify(tel)
      this.botonDeshabilitado = true
      this.$apollo.mutate({
        mutation: clienteCrearTelefonoGql,
        variables: {
          personaId: id || this.personaId,
          numero: telefono
        },
        update: (store, { data: { telefonoCrear } }) => {
          const data = store.readQuery({
            query: clienteDireccionesGql,
            variables: {
              filter: {
                id: this.personaId
              }
            }
          })
          const msg = telefonoCrear.codigo
          switch (msg) {
            case 'Correcto':
              this.alertaMensaje = {
                contenido: 'Se ha creado el teléfono exitosamente',
                tipo: 'correcto'
              }
              break
            case 'Fallido':
              this.botonDeshabilitado = false
              return (this.alertaMensaje = {
                contenido: 'Ha ocurrido un error creando el teléfono. Por favor intenta de nuevo',
                tipo: 'error'
              })
            default:
              this.botonDeshabilitado = false
              return this.mostrarMensajeEnCasoDefault()
          }
          const direcciones = JSON.parse(JSON.stringify(data.Cliente[0].direcciones))
          const direccionesActualizadas = []
          direcciones.forEach((d) => {
            if (d?.recibe && d.recibe?.id.length && d.recibe.id === id) {
              d.recibe.telefonos.push({ numero: telefono, __typename: 'Telefono' })
            }
            direccionesActualizadas.push(d)
          })
          data.Cliente[0].direcciones = direccionesActualizadas
          store.writeQuery({
            query: clienteDireccionesGql,
            variables: {
              filter: {
                id: this.personaId
              }
            },
            data
          })
          if (!this.botonDeshabilitado) {
            const direccionSeleccionada = this.cliente.direcciones
              .filter(d => d.id === this.direccionSeleccionada.id)
            this.direccionSeleccionada = direccionSeleccionada[0]
            this.mostrarNuevoRTelefono = false
            this.nuevoRTelefono = ''
          }
          this.botonDeshabilitado = false
        },
        optimisticResponse: {
          __typename: 'Mutation',
          telefonoCrear: {
            __typename: 'Resultado',
            codigo: 'Correcto'
          }
        }
      })
        .catch(() => {
          this.mostrarMensajeEnCasoDefault()
          this.botonDeshabilitado = false
        })
    },
    telefonoRemover (num, id) {
      let numero = num
      if (typeof numero !== 'string') {
        numero = JSON.stringify(num)
      }
      this.botonDeshabilitado = true
      this.$apollo.mutate({
        mutation: clienteEliminarTelefonoGql,
        variables: {
          numero,
          personaId: id || this.personaId
        },
        update: (store, { data: { telefonoEliminar } }) => {
          const data = store.readQuery({
            query: clienteDireccionesGql,
            variables: {
              filter: {
                id: this.personaId
              }
            }
          })
          const msg = telefonoEliminar.codigo
          switch (msg) {
            case 'Correcto':
              this.alertaMensaje = {
                contenido: 'Se ha removido el teléfono exitosamente',
                tipo: 'correcto'
              }
              break
            case 'Fallido':
              this.botonDeshabilitado = false
              return (this.alertaMensaje = {
                contenido: 'Ha ocurrido un error eliminando el teléfono. Por favor intenta de nuevo',
                tipo: 'error'
              })
            default:
              this.botonDeshabilitado = false
              return this.mostrarMensajeEnCasoDefault()
          }
          const direcciones = JSON.parse(JSON.stringify(data.Cliente[0].direcciones))
          const direccionesActualizadas = []
          direcciones.forEach((d) => {
            if (d.recibe && d.recibe.id === id) {
              d.recibe.telefonos = d.recibe.telefonos.filter((e) => {
                return e.numero !== numero
              })
            }
            direccionesActualizadas.push(d)
          })
          data.Cliente[0].direcciones = direccionesActualizadas
          store.writeQuery({
            query: clienteDireccionesGql,
            variables: {
              filter: {
                id: this.personaId
              }
            },
            data
          })
          if (!this.botonDeshabilitado) {
            const direccionSeleccionada = this.cliente.direcciones
              .filter(d => d.id === this.direccionSeleccionada.id)
            this.direccionSeleccionada = direccionSeleccionada[0]
            this.editarTelefono = false
            this.telefonoEditarSeleccionado = false
          }
          this.botonDeshabilitado = false
        },
        optimisticResponse: {
          __typename: 'Mutation',
          telefonoEliminar: {
            __typename: 'Resultado',
            codigo: 'Correcto'
          }
        }
      })
        .catch(() => {
          this.mostrarMensajeEnCasoDefault()
          this.botonDeshabilitado = false
        })
    },
    telefonoEditar (tel, id) {
      const telefono = String(tel)
      this.botonDeshabilitado = true
      this.$apollo.mutate({
        mutation: clienteEditarTelefonoGql,
        variables: {
          numeroAnterior: String(this.rTelefonoAEditar.telefonoAnterior),
          personaId: id || this.personaId,
          numeroNuevo: telefono
        },
        update: (store, { data: { telefonoEditar } }) => {
          const data = store.readQuery({
            query: clienteDireccionesGql,
            variables: {
              filter: {
                id: this.personaId
              }
            }
          })
          const msg = telefonoEditar.codigo
          switch (msg) {
            case 'Correcto':
              this.alertaMensaje = {
                contenido: 'Se ha actualizado el teléfono exitosamente',
                tipo: 'correcto'
              }
              break
            case 'Fallido':
              this.botonDeshabilitado = false
              return (this.alertaMensaje = {
                contenido: 'Ha ocurrido un error actualizando el teléfono. Por favor intenta de nuevo',
                tipo: 'error'
              })
            default:
              this.botonDeshabilitado = false
              return this.mostrarMensajeEnCasoDefault()
          }
          const direcciones = JSON.parse(JSON.stringify(data.Cliente[0].direcciones))
          const direccionesActualizadas = []
          direcciones.forEach((d) => {
            if (d?.recibe && d.recibe?.id.length && d.recibe.id === id) {
              d.recibe.telefonos.forEach(tel => {
                if (this.rTelefonoAEditar.telefonoAnterior === tel.numero) {
                  tel.numero = this.rTelefonoAEditar.telefonoNuevo
                }
              })
            }
            direccionesActualizadas.push(d)
          })
          data.Cliente[0].direcciones = direccionesActualizadas
          store.writeQuery({
            query: clienteDireccionesGql,
            variables: {
              filter: {
                id: this.personaId
              }
            },
            data
          })
          if (!this.botonDeshabilitado) {
            const direccionSeleccionada = this.cliente.direcciones
              .filter(d => d.id === this.direccionSeleccionada.id)
            this.direccionSeleccionada = direccionSeleccionada[0]
            this.editarRTelefono = false
            this.rTelefonoEditarSeleccionado = false
          }
          this.botonDeshabilitado = false
        },
        optimisticResponse: {
          __typename: 'Mutation',
          telefonoEditar: {
            __typename: 'Resultado',
            codigo: 'Correcto'
          }
        }
      })
        .catch(() => {
          this.mostrarMensajeEnCasoDefault()
          this.botonDeshabilitado = false
        })
    },
    // Limpiar Modal CRUD teléfonos
    limpiarModalCRUDTelefonos () {
      this.mostrarCRUDTelefonos = false
      this.mostrarNuevoRTelefono = false
      this.editarRTelefono = false
      this.direccionSeleccionada = {}
    }
  },
  apollo: {
    cliente () {
      return {
        query: clienteDireccionesGql,
        variables: {
          filter: {
            id: leerPersonaId()
          }
        },
        update: (data) => data.Cliente[0],
        fetchPolicy: 'cache-and-network'
      }
    }
  }
}

</script>

<style lang="scss" scoped>
.col-auto.acciones {
  width: 64px;
  @media screen and (min-width: 768px){width: 120px;}
}
</style>
